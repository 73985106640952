.packages {
  width: 80%;
  margin: auto;
}
.packages h1 {
  font-size: 1.4rem;
  padding-bottom: 1.2rem;
  font-family: "PT Sans", sans-serif;
}
.packages ul li {
  list-style: disc;
}
.packages ul {
  padding-left: 2rem;
}
.packages h4 {
  margin: 0.2rem 0rem 0.5rem;
  font-family: "Poppins", sans-serif;
}
.packages h3 {
  font-family: "Poppins", sans-serif;

  margin: 0.6rem 0rem 0.2rem;
}
#pack {
  padding-bottom: 0.7rem;
  font-size: 2rem;
  width: 85%;
  margin: auto;
}
.fa-angle-right {
  font-size: 1.2rem;
  padding-left: 0.5rem;
}
.fa-caret-down {
  font-size: 1.2rem;
  padding-left: 0.5rem;
}
.packages .images {
  width: 90vw;
}

.packages img {
  max-width: 90%;
  height: auto;
}
#packdet {
  color: rgb(7, 168, 168);
  padding: 1.4rem 0rem 2rem;
  font-size: 1.8rem;
}
.packages h1 {
  color: rgb(209, 92, 92);
  padding: 1rem 0rem ;
  font-size: 1.8rem;
}
