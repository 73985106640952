.navbar {
  background: #003e50;
  position: sticky;
  top: 0;
  z-index: 999;
  height: 10vh;
  line-height: 10vh;
}

nav ul li {
  display: inline-block;
  transition: all 0.2s ease-out;
  margin: 0 30px 0 0;
}

nav ul li a {
  color: #fff;
  font-weight: 100;
  font-size: 15px;
  letter-spacing: 1px;
  transition: all 0.2s ease-out;
  opacity: 0.8;
  font-size: 1rem !important;
  font-weight: bold !important;
}
nav ul li a:hover {
  color: #17ed86;
  letter-spacing: 0.2rem;
}

.container .logo {
  scale: 3;
  padding-top: 0.8rem;
  transition: all 0.2s ease-out;
}
.container .logo img {
  /* background: red; */
  height: 70px;
}
.container .logo:hover {
  scale: 4;
}
.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
nav i {
  color: #fff;
}
.login-area {
  color: white;
}

.login-area a {
  font-weight: 100;
  opacity: 0.8;
  font-size: 13px;
  color: #fff;
}
.login-area i {
  margin: 0 5px 0 15px;
  font-size: 13px;
  color: #fff;
}
.login-area button {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .login-area {
    position: absolute;
    right: 20px;
  }
  .login-area ul li {
    display: none;
  }
  .navbar {
    /* background: red; */
    min-height: 10vh;
    text-align: center;
  }

  nav ul {
    display: block;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease-in;
  }

  nav ul.active {
    background: #313533;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
  }

  nav ul.active + .navbar {
    min-height: 15vh;
  }

  nav ul li {
    display: block;
  }
  nav ul.active {
    background: #313533;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
  }
  nav ul li a {
    font-weight: 400;
    opacity: 1;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    font-size: 2rem;
  }
}
/*--------header------------*/
header {
  /* padding: 15px 0 20px 0; */
  background-color: #fff;
}
header .box {
  margin: 0 0 0 30px;
}
header a {
  font-size: 13px;
  color: grey;
  transition: all 0.5s ease;
}
header a:hover {
  font-size: 15px;
}
header i {
  font-size: 40px;
  color: #003e50;
  margin-right: 15px;
}
header .text {
  margin-top: 10px;
}
header h4 {
  line-height: 10px;
}
header .contact {
  display: flex;
  align-items: center;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  header {
    padding: 10px 0 20px 0;
    background-color: #fff;
  }
  /*--------header--------*/
  header .container {
    /* background-color: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  header .contact {
    display: flex;
    /* background: red; */
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
  }
  header a {
    font-size: 11px;
  }
  header i {
    font-size: 22px;
    margin-right: 8px;
    margin-top: 6px;
  }
  header h4 {
    font-size: 15px;
  }
  header h4 {
    line-height: 18px;
  }
  /*--------header--------*/
}
.text-dark {
  background-color: #49dfd5;
  color: black !important;
  border-radius: 8px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}
.text-dark:hover {
  background-color: #333;
  color: #fff !important;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.box .text {
  transition: all 0.3s ease;
}
.box .call:hover {
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 768px) {
  header .contact {
    display: none;
  }
}
.vcard{
  background: rgb(194, 201, 194);
}