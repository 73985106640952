.desi .title {
  margin: 0;
  height: 8vh;
  background-color: #003e50;
}
.desi h3 {
  margin: 0;
  padding: 15px 0 15px 0;
  color: white;
}
.bro-title {
  background: #31b675;
  background-color: #09b4a9;

  height: 8vh;
  display: flex;
  justify-content: center;
  /* color: blue; */
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .desi .title {
    height: 9vh;
  }

}

@media screen and (max-width: 568px) {
  .desi .title {
    height: 7vh;
  }
}
