.slide img {
  height: 75vh;
  object-fit: cover;
  filter: brightness(70%);
}

.slide-container {
  position: relative;
}

.slide-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
  text-decoration: underline;
  font-family: "Playfair Display", serif;
  font-size: 3.6rem;
  text-decoration: none;
}
.slide-text-para {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
  font-family: "Playfair Display", serif;
  font-size: 2.8rem;
}
@media only screen and (max-width: 767px) {
  .fas.fa-caret-left,
  .fas.fa-caret-right {
    font-size: 1.8em;
    width: 46px;
    height: 46px;
  }
  .slide-text {
    font-size: 1.6rem;
  }
  .slide-text-para {
  top: 60%;
    font-size: 0.7rem;

  }
}

@media (max-width: 568px) {
  .control-btn {
    display: none;
  }
  .slide img {
    height: 50vh;
    object-fit: cover;
    aspect-ratio: 3/2;
    width: 100%;
  }
  .slide-text {
    font-size: 1.3rem;
  }
  .slide-text-para {
  top: 60%;
    font-size: 0.8rem;

  }
}

.prev i,
.next i {
  background: #02556e;
}
