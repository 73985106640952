.slider {
  overflow: hidden;
  position: relative;
}

.slider img {
  width: 100%;
  /* height: 100vh; */
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}

.control-btn .next {
  position: absolute;
  top: 40%;
  z-index: 10;
  right: 5%;
}

.control-btn .prev {
  position: absolute;
  top: 40%;
  z-index: 10;
  left: 5%;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
/*--------form-----------*/
.slide-form h2 {
  margin-top: 30px;
}
.slide-form {
  position: absolute;
  top: 30%;
  left: 10%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 35%;
  height: 65vh;
  border-radius: 5px;
  color: white;
}
.slide-form input {
  margin-top: 20px;
}
.slide-form .flex_space input:nth-child(odd) {
  margin-right: 20px;
}
.slide-form .submit {
  background: #31b675;
  color: white;
}
/*--------form-----------*/
@media screen and (max-width: 768px) {
  /* .slider {
    height: 65vh;
  } */
  .slider img {
    object-fit: cover;
  }

  .slide-form {
    left: 5%;
    top: 32%;
    width: 90%;
    height: 55vh;
  }
}
