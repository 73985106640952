.box.selected {
  border: none;
}
.collection img {
  filter: grayscale(100%);
  transition: 0.5s;
}
.collection img:hover {
  filter: grayscale(0);
}

.collection img {
  width: 55%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
