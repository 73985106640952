footer {
  background-color: #000;
  color: white;
  margin-top: 50px;
  padding: 50px 0;
}
footer .grid {
  grid-template-columns: 1fr 8rem;
}
footer h2 {
  margin-bottom: 20px;
  letter-spacing: 2px;
}
footer p {
  font-size: 13px;
  opacity: 0.6;
  font-weight: 300;
  letter-spacing: 1px;
}
footer .icon i {
  color: black;
  margin: 0rem 2rem;
}
footer ul li a {
  opacity: 0.7;
  color: white;
  font-size: 13px;
  transition: all 200ms ease-in-out;
}
footer ul li a:hover {
  color: white;
  font-size: 15px;
}
footer ul li {
  margin-bottom: 10px;
}
footer .post li {
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(63, 62, 62);
}
footer .post li:nth-last-child(1) {
  border-bottom: none;
}
footer .post label,
footer .post span {
  opacity: 0.5;
  margin-right: 10px;
  font-size: 13px;
  margin-bottom: 20px;
}
footer input {
  background: none;
  border: 1px solid rgb(63, 62, 62);
  border-radius: 5px;
  margin: 10px 0;
}
footer .primary-btn {
  padding: 20px 20px;
  border-radius: 0px;
  text-align: center;
}
.legal {
  text-align: center;
  padding: 20px;
  background-color: #000;
  border-top: 1px solid rgb(63, 62, 62);
  color: white;
}
.legal p {
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  footer .grid {
    grid-template-columns: 1fr;
  }
  footer .icon i {
    color: black;
    margin: 1rem;
  }
}
footer .grid .box ul li p {
  display: none;
}

@media screen and (max-width: 768px) {
  footer .grid .box ul li p {
    display: block;
  }
  footer .grid .box ul li p {
    color: white;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    font-size: 12px;
  }
  footer .grid .box ul li p:hover {
    font-size: 14px;
  }
}
 i{
  cursor: pointer;
 }