.back {
  position: absolute;
  right: 8%;
  top: -70px;
}
.single-page {
  position: relative;
}

.main-content p {
  margin: 10px 0 20px 0;
  color: grey;
}
.main-content .para p {
  margin-right: 10px;
}
.main-content h1 {
  font-size: 30px;
}
.main-content img {
  width: 100%;
  height: auto;
}

article .box {
  background-color: #31b675;
  background-color: #09b4a9;

  
  padding: 30px;
  color: white;
}
article .box h2 {
  font-size: 30px;
  font-weight: 500;
}
article .box2 {
  margin-top: 40px;
  background: url("../../public/Images/side-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
  width: 100%;
  position: relative;
  padding: 20px;
}
article .box2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 180px;
  width: 100%;
}
article p {
  font-size: 14px;
  line-height: 25px;
}
article .box2 p {
  text-align: center;
  position: relative;
  z-index: 2;
  font-size: 14px;
  line-height: 25px;
  color: white;
}
@media screen and (max-width: 768px) {
  .single-page .flex {
    flex-direction: column;
  }
}
.primary-btn  {
  background: #09b4a9;
}