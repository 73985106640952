.gallery {
  margin: 50px 0;
}

.gallery img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery .items {
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: 2px 10px 13px 0px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 2px 10px 13px 0px rgba(0, 0, 0, 0.54);
  box-shadow: 2px 10px 13px 0px rgba(0, 0, 0, 0.54);
}
.gallery h3 {
  font-size: 0.9rem;
  margin: 10px 0;
  font-weight: 400;
}
.gallery .img {
  position: relative;
  overflow: hidden;
  height: 30vh;
  width: 20vw;
  /* width: 380px; */
  /* height: 252px; */
}

.gallery i {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 5;
  font-size: 40px;
  color: white;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.gallery .img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  height: 552px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.gallery .img::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 360px;
  height: 532px;
  z-index: 3;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.gallery .items:hover img {
  transform: scale(1.2);
  cursor: pointer;
}
.gallery .items:hover i,
.gallery .items:hover .img::after,
.gallery .items:hover .img::before {
  opacity: 1;
  cursor: pointer;
}
.popup {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 8;
}

.hide {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.popup-content {
  position: absolute;
  top: 15%;
  left: 20%;
}
.popup button {
  background-color: #fff;
  padding: 20px 20px;
  color: #000;
  position: absolute;
  top: -7%;
  right: -34.5%;
  font-weight: bold;
}
.gallery .img {
  height: 38vh;
  width: 22vw;
}
@media screen and (max-width: 768px) {
  .gallery .container {
    max-width: 100%;
  }

  .gallery .img {
    height: 40vh;
    width: 50vw;
  }
  .gallery .img::after {
    width: 300px;
    height: 180px;
  }
  .popup-content {
    padding: 50px;
    position: absolute;
    top: 30%;
    left: 0%;
  }
  .popup button {
    background-color: #fff;
    padding: 20px 20px;
    color: #000;
    position: absolute;
    top: -2%;
    right: 6.5%;
    font-weight: bold;
  }
}

.gallery .htp .htpcard {
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 568px) {
  .gallery .img {
    height: 28vh;
    width: 67vw;
  }
}
