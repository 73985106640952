.works {
  text-align: center;
}
.works img {
  width: 20%;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.works .box {
  padding: 40px 20px;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}
.works h2 {
  font-weight: 500;
  font-size: 20px;
  margin: 15px 0;
}
.works p {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .works .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}


.popular.works.animate-section {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.8s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



