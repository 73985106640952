.Testimonial .box {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 20px;
  position: relative;
  z-index: 2;
  background-color: white;
  border-radius: 5px;
}
.Testimonial .box p {
  cursor: pointer;
  font-size: 13px;
  font-style: italic;
  line-height: 24px;
  font-weight: 400;
  opacity: 0.5;
}
.Testimonial .box hr {
  height: 2px;
  background-color: black;
  margin: 20px 0 20px 0;
  opacity: 0.2;
}
.Testimonial .box .img {
  cursor: pointer;
  margin-right: 20px;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.Testimonial .box img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}
.Testimonial .box h3 {
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 1px;
}
.Testimonial .box span {
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}
.Testimonial .box i {
  font-size: 170px;
  position: absolute;
  top: 20%;
  left: 35%;
  color: #f1f1f1;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .Testimonial .grid1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.teamhead {
  text-align: center;
  font-size: 2.8rem;
  margin: 2rem 0rem;
}
